// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li{
  font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
}
li > a{
    
    font-weight: 300;
    padding-left: 5px;
    font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;

}

a{
  font-size: 14px;
  color: #551A8b;
  font-weight: 300;
}

.Breadcrumbs_breadcrumbs__xrVnQ > span{
  font-size: 16px;
    font-weight: 600;
    padding-right: 5px;
    font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.Breadcrumbs_breadcrumbs__xrVnQ {
    margin: auto;
    padding-bottom: 3%;
  }
  
ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.module.css"],"names":[],"mappings":"AAAA;EACE,gFAAgF;EAChF,eAAe;AACjB;AACA;;IAEI,gBAAgB;IAChB,iBAAiB;IACjB,gFAAgF;;AAEpF;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;IACb,gBAAgB;IAChB,kBAAkB;IAClB,gFAAgF;AACpF;;AAEA;IACI,YAAY;IACZ,kBAAkB;EACpB;;AAEF;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,kBAAkB;EACpB","sourcesContent":["li{\n  font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-size: 16px;\n}\nli > a{\n    \n    font-weight: 300;\n    padding-left: 5px;\n    font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;\n\n}\n\na{\n  font-size: 14px;\n  color: #551A8b;\n  font-weight: 300;\n}\n\n.breadcrumbs > span{\n  font-size: 16px;\n    font-weight: 600;\n    padding-right: 5px;\n    font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;\n}\n\n.breadcrumbs {\n    margin: auto;\n    padding-bottom: 3%;\n  }\n  \nul {\n    display: flex;\n    flex-wrap: wrap;\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    text-align: center;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `Breadcrumbs_breadcrumbs__xrVnQ`
};
export default ___CSS_LOADER_EXPORT___;
