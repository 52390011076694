import React, { useEffect, useRef } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styles from './LoginModal.module.css';
import { FaSpotify } from 'react-icons/fa';
import { subscribeUser } from '../../services/api';
import { toast } from 'react-toastify';

function LoginModal({ isOpen, onClose, onGoogleLogin, onSpotifyLogin, formspreeId, handleSubscriptionSuccess }) {
  const modalRef = useRef(null);
  const [state, handleSubmit] = useForm(formspreeId);
  const toastShown = useRef(false);

  useEffect(() => {
    if (state.succeeded && !toastShown.current) {
      const email = state.submissionData?.email || state.values?.email;
      if (email) {
        subscribeUser(email)
          .then(response => {
            if (response.message && response.message.includes('Existing subscription found')) {
              handleSubscriptionSuccess(email, response.freeSearches || 0);
            } else {
              handleSubscriptionSuccess(email, response.freeSearches || 100);
            }
            toast.success(`Successfully subscribed! You now have ${response.freeSearches || 100} free searches.`);
            toastShown.current = true;
          })
          .catch(error => {
            console.error('Subscription error:', error);
            toast.error('Failed to subscribe. Please try again.');
          });
      }
    }
  }, [state.succeeded, handleSubscriptionSuccess]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div ref={modalRef} className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <img src="/singit-logo.webp" alt="Singit logo" className={styles.logo} />
        </div>
        <h2 className={styles.modalTitle}>Search Limit Reached</h2>
        <p className={styles.modalSubtitle}>Sign in, register, or subscribe to get more free searches</p>
        <div className={styles.loginButtons}>
          <button onClick={onGoogleLogin} className={`${styles.loginButton} ${styles.googleButton}`}>
            <div className={styles.googleIcon}></div>
            <span>Sign in with Google</span>
          </button>
          <button onClick={onSpotifyLogin} className={`${styles.loginButton} ${styles.spotifyButton}`}>
            <FaSpotify className={styles.buttonIcon} />
            <span>Sign in with Spotify</span>
          </button>
        </div>
        <div className={styles.divider}>
          <span>or</span>
        </div>
        {state.succeeded ? (
          <p className={styles.successMessage}>Thanks for subscribing! You now have 100 free searches.</p>
        ) : (
          <form onSubmit={handleSubmit} className={styles.subscribeForm}>
            <input 
              id="email"
              type="email" 
              name="email"
              placeholder="Enter your email"
              required 
              className={styles.emailInput}
            />
            <ValidationError 
              prefix="Email" 
              field="email"
              errors={state.errors}
              className={styles.errorMessage}
            />
            <button type="submit" disabled={state.submitting} className={styles.subscribeButton}>
              {state.submitting ? 'Subscribing...' : 'Get 100 Free Searches'}
            </button>
          </form>
        )}
        <p className={styles.privacyText}>
          By signing up, you agree to our Terms and Privacy Policy.
        </p>
      </div>
    </div>
  );
}

export default LoginModal;