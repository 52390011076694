// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeroSection_heroSection__HGwVp {
  text-align: center;
}

.HeroSection_headline__FrOqe {
  font-size: 24px;
  color: #333;
  margin: 40px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/HeroSection/HeroSection.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,cAAc;AAChB","sourcesContent":[".heroSection {\n  text-align: center;\n}\n\n.headline {\n  font-size: 24px;\n  color: #333;\n  margin: 40px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroSection": `HeroSection_heroSection__HGwVp`,
	"headline": `HeroSection_headline__FrOqe`
};
export default ___CSS_LOADER_EXPORT___;
