import React, { useContext, useState } from 'react';
import { AuthContext } from '../../App';
import SearchBar from '../SearchBar/SearchBar';
import DownloadSection from '../DownloadSection/DownloadSection';
import styles from './HeroSection.module.css';

function HeroSection({ onSearch, onLimitReached }) {
  const { user, remainingSearches, setShowLoginModal } = useContext(AuthContext);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleSearch = (result) => {
    console.log("Search results in HeroSection:", result);
    if (result) {
      setSearchPerformed(true);
      onSearch(result);
    }
  };

  const handleLimitReached = () => {
    setShowLoginModal(true);
    onLimitReached();
  };

  return (
    <section className={styles.heroSection}>
      <SearchBar onSearch={handleSearch} onLimitReached={handleLimitReached} />
      {!user && remainingSearches !== null && !searchPerformed && (
        <p className={styles.remainingSearches}>
          {remainingSearches !== null 
            ? `${remainingSearches} free searches remaining`
            : 'Unlimited searches available'}
        </p>
      )}
      {!searchPerformed && (
        <>
          <h1 className={styles.headline}>Start learning English for free, with our 3-day free trial.</h1>
          <DownloadSection />
        </>
      )}
    </section>
  );
}

export default HeroSection;