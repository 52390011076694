import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
import styles from './App.module.css';
import SearchResults from './components/SearchResults/SearchResults';
import SongView from './components/SongView/SongView';
import ArtistView from './components/ArtistView/ArtistView';
import AlbumView from './components/AlbumView/AlbumView';
import Header from './components/Header/Header';
import RecentSearchesView from './components/RecentSearchesView/RecentSearchesView';
import TopArtistsView from './components/TopArtistsView/TopArtistsView';
import HeroSection from './components/HeroSection/HeroSection';
import FeatureSection from './components/FeatureSection/FeatureSection';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import NewsletterSubscription from './components/NewsletterSubscription/NewsletterSubscription';
import DownloadSection from './components/DownloadSection/DownloadSection';
import FooterComp from './components/footer/footer';
import TagView from './components/TagView/TagView';
import PromoBanner from './components/PromoBanner/PromoBanner';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import LoginModal from './components/LoginModal/LoginModal';
import { FormspreeProvider } from '@formspree/react';
import { loginWithGoogle, loginWithSpotify, handleOAuthCallback, checkSubscription, verifyToken, isAuthenticated, subscribeUser, checkAuthStatus, logout, subscriptionLogout } from './services/authService';

export const AuthContext = createContext(null);

export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

function ScrollToTop() {
  useScrollToTop();
  return null;
}

function OAuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser, setIsAuthenticated, setSubscribedEmail, setRemainingSearches } = useContext(AuthContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      handleOAuthCallback(token)
        .then(authStatus => {
          console.log('OAuth callback authStatus:', authStatus);
          setIsAuthenticated(true);
          setUser(authStatus.user);
          setSubscribedEmail(authStatus.subscribedEmail);
          setRemainingSearches(authStatus.remainingSearches);
          localStorage.setItem('token', token);
          toast.success('Successfully logged in');
          navigate('/');
        })
        .catch(error => {
          console.error('OAuth callback error:', error);
          toast.error('Login failed. Please try again.');
          navigate('/login');
        });
    } else {
      toast.error('No token received. Please try logging in again.');
      navigate('/login');
    }
  }, [location, navigate, setUser, setIsAuthenticated, setSubscribedEmail, setRemainingSearches]);

  return <div>Processing login...</div>;
}
function SEOHelmet({ title, description, schema }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
}

function App() {
  const [user, setUser] = useState(null);
  const [subscribedEmail, setSubscribedEmail] = useState(null);
  const [searchResults, setSearchResults] = useState({ artists: [], tracks: [] });
  const [currentView, setCurrentView] = useState('home');
  const [selectedSong, setSelectedSong] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [currentArtistName, setCurrentArtistName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [remainingSearches, setRemainingSearches] = useState(() => {
    const storedSearches = localStorage.getItem('remainingSearches');
    return storedSearches ? parseInt(storedSearches, 10) : null;
  });

  useEffect(() => {
    const initializeApp = async (retryCount = 0) => {
      try {
        const authStatus = await checkAuthStatus();
        console.log('Initial Auth status:', authStatus);
        setIsAuthenticated(authStatus.isAuthenticated);
        setUser(authStatus.user);
        setSubscribedEmail(authStatus.subscribedEmail);
        setRemainingSearches(authStatus.remainingSearches);
        if (authStatus.rateLimited) {
          toast.error('You have reached the maximum number of requests. Please try again later.');
        }
      } catch (error) {
        console.error('Error initializing app:', error);
        if (retryCount < 3) {
          const delay = Math.pow(2, retryCount) * 1000;
          setTimeout(() => initializeApp(retryCount + 1), delay);
        } else {
          toast.error('Unable to connect to the server. Please try again later.');
        }
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    const updateRemainingSearches = async () => {
      if (subscribedEmail) {
        try {
          const subscriptionData = await checkSubscription(subscribedEmail);
          if (subscriptionData && subscriptionData.remainingSearches !== undefined) {
            setRemainingSearches(subscriptionData.remainingSearches);
          }
        } catch (error) {
          console.error('Error updating remaining searches:', error);
        }
      }
    };

    updateRemainingSearches();
  }, [subscribedEmail]);

  const handleSubscriptionSuccess = async (email) => {
    console.log('Subscription success called with email:', email);
    try {
      setIsLoading(true);
      const subscriptionData = await subscribeUser(email);
      console.log('Received subscription data:', subscriptionData);

      setIsAuthenticated(false);
      setUser(null);
      setSubscribedEmail(email);
      setRemainingSearches(subscriptionData.remainingSearches);

      toast.success(`Subscription successful! You have ${subscriptionData.remainingSearches} free searches.`);
      setShowLoginModal(false);

      // Store the subscribed email in localStorage
      localStorage.setItem('subscribedEmail', email);

      // Refresh auth status
      const authStatus = await checkAuthStatus();
      console.log('Auth status after subscription:', authStatus);
      setSubscribedEmail(authStatus.subscribedEmail);
      setRemainingSearches(authStatus.remainingSearches);
    } catch (error) {
      console.error('Error handling subscription:', error);
      toast.error('Error processing subscription. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const checkExistingSubscription = async (email) => {
    try {
      const subscriptionData = await checkSubscription(email);
      console.log('subscriptionData', subscriptionData);
      if (subscriptionData && subscriptionData.remainingSearches !== undefined) {
        console.log('Subscription data:', subscriptionData);
        setRemainingSearches(subscriptionData.remainingSearches);
        return subscriptionData;
      } else {
        setRemainingSearches(10);
        localStorage.setItem('remainingSearches', '10');
        return null;
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      setRemainingSearches(10);
      localStorage.setItem('remainingSearches', '10');
      return null;
    }
  };

  const updateRemainingSearches = (newValue) => {
    if (typeof newValue === 'number' && !isNaN(newValue)) {
      setRemainingSearches(newValue);
      localStorage.setItem('remainingSearches', newValue.toString());
    } else {
      console.error('Invalid remainingSearches value:', newValue);
    }
  };

  const handleLimitReached = () => {
    toast.error('Search limit reached. Please subscribe to continue searching.');
    setShowLoginModal(true);
  };

  const handleLoginModalClose = () => {
    setShowLoginModal(false);
  };

  const handleSearch = async (results) => {
    console.log('handleSearch called with results:', results);
    setIsLoading(true);

    setSearchResults(results);
    setCurrentView('searchResults');

    try {
      const subscriptionData = await checkSubscription();
      setRemainingSearches(subscriptionData.remainingSearches);
    } catch (error) {
      console.error('Error fetching remaining searches:', error);
    }

    setIsLoading(false);
  };

  const handleSongSelect = (song) => {
    setSelectedSong(song);
    setSelectedArtist(null);
    setCurrentView('songView');
  };

  const handleArtistSelect = (artist) => {
    setSelectedArtist(artist);
    setCurrentArtistName(artist.name || artist.artistName);
    setSelectedSong(null);
    setCurrentView('artistView');
  };

  const handleLoginSuccess = async (userData) => {
    setUser(userData);
    setIsAuthenticated(true);
    setShowLoginModal(false);
    toast.success('Successfully logged in');

    // Refresh auth status
    const authStatus = await checkAuthStatus();
    setSubscribedEmail(authStatus.subscribedEmail);
    setRemainingSearches(authStatus.remainingSearches);
  };


  const handleLogout = async () => {
    try {
      await logout();
      setUser(null);
      setSubscribedEmail(null);
      setRemainingSearches(null);
      toast.info('You have been logged out.');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Error logging out. Please try again.');
    }
  };

  const HomeContent = () => (
    <>
      <SEOHelmet
        title="Singit Translation | Translate Songs in Real-Time"
        description="Discover the meaning behind your favorite songs with Singit Translation. Translate lyrics in real-time and learn new languages through music."
        schema={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Singit Translation",
          "url": "https://singit.seo-top.dev",
          "logo": "https://singit.seo-top.dev/singit-logo-512.png",
          "sameAs": [
            "https://www.facebook.com/SingitTranslation",
            "https://twitter.com/SingitTranslate"
          ]
        }}
      />
      <div className={styles.features}>
        <FeatureSection
          title="Sing It"
          description="Choose between millions of your favourite songs to learn their true meanings and in any language"
          imageSrc="/images/sing-it.webp"
          icon="🎤"
        />
        <FeatureSection
          title="Learn It"
          description="Learn and get quizzes of the lyrics that you loved and was most interested about."
          imageSrc="/images/learn-it.webp"
          icon="📚"
        />
        <FeatureSection
          title="Know It"
          description="Know a different language with the power of music that is both enjoyable and meaningful to you."
          imageSrc="/images/know-it.webp"
          icon="🌍"
        />
      </div>
      <DownloadSection />
      <PromoBanner />
    </>
  );

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      subscribedEmail,
      setSubscribedEmail,
      remainingSearches,
      setRemainingSearches,
      isAuthenticated,
      setIsAuthenticated,
      handleSubscriptionSuccess,
      handleLogout,
      setShowLoginModal
    }}>
      <Router>
      <ScrollToTop />
        <div className={styles.app}>
          <Header />
          <main className={styles.main}>
            <HeroSection onSearch={handleSearch} onLimitReached={handleLimitReached} />
            <Breadcrumbs currentView={currentView} song={selectedSong} />
            <Routes>
              <Route path="/" element={<HomeContent />} />
              <Route path="/search-results" element={
                <>
                  <SEOHelmet
                    title="Search Results | Singit Translation"
                    description="Find your favorite songs and artists on Singit Translation. Explore lyrics and translations."
                    schema={{
                      "@context": "https://schema.org",
                      "@type": "SearchResultsPage",
                      "name": "Singit Translation Search Results"
                    }}
                  />
                  {searchResults && (
                    <SearchResults 
                    searchResult={searchResults} 
                    onSongSelect={handleSongSelect}
                    onArtistSelect={handleArtistSelect}
                    onSuggestionSelect={(suggestion) => handleSearch(suggestion)}
                  />
                  )}
                </>
              } />
              <Route path="/tag/:tag" element={<TagView />} />
              <Route path="/song/:artist/:title" element={<SongView />} />
              <Route path="/album/:artist/:album" element={<AlbumView />} />
              <Route path="/artist/:artistName" element={<ArtistView artist={selectedArtist} onSongSelect={handleSongSelect} />} />
              <Route path="/oauth-callback" element={<OAuthCallback />} />
              <Route path="/recent-searches" element={<RecentSearchesView />} />
              <Route path="/top-artists" element={<TopArtistsView />} />
            </Routes>
          </main>
          <NewsletterSubscription artist={currentArtistName} />
          <FooterComp />
        </div>
        {isLoading && <LoadingIndicator />}
        <FormspreeProvider>
          <LoginModal
            isOpen={showLoginModal}
            onClose={handleLoginModalClose}
            onGoogleLogin={loginWithGoogle}
            onSpotifyLogin={loginWithSpotify}
            formspreeId="xzzpgakr"
          />
        </FormspreeProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;