import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../App';
import styles from './Header.module.css';
import { FaGoogle, FaUserCircle, FaSignOutAlt, FaBars, FaTimes, FaSearch, FaStar } from 'react-icons/fa';
import { getRecentSearches, getTopArtists } from '../../services/api';

const Header = () => {
  const { user, subscribedEmail, remainingSearches, setShowLoginModal, isAuthenticated, handleLogout } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [topArtists, setTopArtists] = useState([]);
  const navigate = useNavigate();

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const toggleUserMenu = () => setShowUserMenu(!showUserMenu);

  useEffect(() => {
    const fetchRecentSearches = async () => {
      try {
        const data = await getRecentSearches();
        setRecentSearches(data.recentSearches || []);
      } catch (error) {
        console.error('Error fetching recent searches:', error);
      }
    };

    const fetchTopArtists = async () => {
      try {
        const data = await getTopArtists();
        setTopArtists(data || []);
      } catch (error) {
        console.error('Error fetching top artists:', error);
      }
    };

    fetchRecentSearches();
    fetchTopArtists();
  }, []);

  useEffect(() => {
    const closeMenus = (e) => {
      if (!e.target.closest(`.${styles.navLinks}`) && !e.target.closest(`.${styles.mobileMenuButton}`)) {
        setShowUserMenu(false);
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener('click', closeMenus);
    return () => document.removeEventListener('click', closeMenus);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'visible';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isMobileMenuOpen]);

  const handleRecentSearchesClick = () => {
    navigate('/recent-searches');
    setIsMobileMenuOpen(false);
  };

  const handleTopArtistsClick = () => {
    navigate('/top-artists');
    setIsMobileMenuOpen(false);
  };

  const renderUserStatus = () => {
    if (isAuthenticated && user) {
      return (
        <div className={styles.userContainer} onClick={(e) => e.stopPropagation()}>
          <button onClick={toggleUserMenu} className={styles.userButton}>
            {user.profilePicture ? (
              <img src={user.profilePicture} alt={user.name} className={styles.userAvatar} />
            ) : (
              <FaUserCircle className={styles.userIcon} />
            )}
          </button>
          {showUserMenu && (
            <div className={styles.userMenu}>
              <div className={styles.userInfo}>
                <span className={styles.userName}>{user.name}</span>
                <span className={styles.userEmail}>{user.email}</span>
              </div>
              <div className={styles.searchesInfo}>
                {remainingSearches !== null ? `${remainingSearches} searches left` : 'Unlimited searches'}
              </div>
              <button onClick={handleLogout} className={styles.logoutButton}>
                <FaSignOutAlt className={styles.logoutIcon} />
                Sign out
              </button>
            </div>
          )}
        </div>
      );
    } else if (subscribedEmail) {
      return (
        <div className={styles.subscribedUser}>
          <span>{subscribedEmail}</span>
          <span>{remainingSearches > 0 ? `${remainingSearches} searches left` : 'No searches left'}</span>
        </div>
      );
    } else {
      return (
        <button onClick={() => setShowLoginModal(true)} className={styles.loginButton}>
          <img src="/singit-logo.webp" alt="Singit logo" />
          <span className={styles.buttonText}>Sign in</span>
        </button>
      );
    }
  };

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <button
          className={`${styles.mobileMenuButton} ${isMobileMenuOpen ? styles.open : ''}`}
          onClick={(e) => { e.stopPropagation(); toggleMobileMenu(); }}
          aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <Link to="/" className={styles.logoLink}>
          <img src="/singit-logo.webp" alt="Singit logo" className={styles.logo} />
        </Link>
        <div className={`${styles.navLinks} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
          <div className={styles.mobileMenuContent}>
            <img src="/singit-logo.webp" alt="Singit logo" className={styles.mobileMenuLogo} />
            <Link to="/" className={styles.navLink} onClick={() => setIsMobileMenuOpen(false)}>Singit Lyrics Translator</Link>
            <button className={styles.navButton} onClick={handleRecentSearchesClick}>
              <FaSearch className={styles.navButtonIcon} />
              Recent Searches
            </button>
            <button className={styles.navButton} onClick={handleTopArtistsClick}>
              <FaStar className={styles.navButtonIcon} />
              Top Artists
            </button>
          </div>
        </div>
        <div className={styles.userProfileMenu}>
          {renderUserStatus()}
        </div>
      </nav>
    </header>
  );
};

export default Header;