import React, { useState, useEffect } from 'react';
import { getRecentSearches } from '../../services/api';
import MultipleSongsView from '../MultipleSongsView/MultipleSongsView';
import styles from './RecentSearchesView.module.css';

const RecentSearchesView = () => {
  const [userSearches, setUserSearches] = useState([]);
  const [generalSearches, setGeneralSearches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecentSearches = async () => {
      try {
        setIsLoading(true);
        const data = await getRecentSearches();
        console.log('Received data:', data);
        setUserSearches(data.userSearches || []);
        setGeneralSearches(data.recentSearches || []);
      } catch (error) {
        console.error('Error fetching recent searches:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentSearches();
  }, []);

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.recentSearchesView}>
      {userSearches.length > 0 && (
        <>
          <h1 className={styles.recentSearchesViewH1}>Your Recent Searches</h1>
          <MultipleSongsView results={userSearches} />
        </>
      )}
      <h1 className={styles.recentSearchesViewH1}>General Recent Searches</h1>
      <MultipleSongsView results={generalSearches} />
    </div>
  );
};

export default RecentSearchesView;