import axios from 'axios';
import Fuse from 'fuse.js';

const API_URL = process.env.REACT_APP_API_URL || 'https://singit.seo-top.dev/api/v1';


const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear the token and redirect to login
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const searchGeneral = async (query) => {
  try {
    console.log('Sending search request for:', query);
    const response = await api.get('/search', { 
      params: { q: query },
      headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' }
    });
    
    console.log('Raw response:', response);

    if (response.status !== 200) {
      console.error('Non-200 status code:', response.status);
      throw new Error(`Unexpected status code: ${response.status}`);
    }

    const data = response.data;
    console.log('Parsed response data:', data);

    if (data.type === 'empty') {
      return { type: 'empty', results: [], remainingSearches: data.remainingSearches };
    }

    if (data.type === 'mixed') {
      return {
        type: 'mixed',
        results: data.results,
        suggestions: data.suggestions,
        remainingSearches: data.remainingSearches
      };
    }

    // If we get here, something unexpected happened
    console.error('Unexpected response structure:', data);
    return { type: 'error', message: 'Unexpected response structure', remainingSearches: data.remainingSearches };

  } catch (error) {
    console.error('Search error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    if (error.response && error.response.status === 429) {
      throw new Error('RATE_LIMIT_REACHED');
    }
    throw error;
  }
};

export const searchByTag = async (tag) => {
  try {
    const response = await api.get('/search/tag', { 
      params: { tag },
      headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' }
    });
    return {
      results: Array.isArray(response.data) ? response.data : [],
      remainingSearches: response.data.remainingSearches
    };
  } catch (error) {
    console.error('Error in tag search:', error);
    return { results: [], remainingSearches: null };
  }
};

export const getSongByArtistAndTitle = async (artist, title, viewType = 'single') => {
  try {
      const formattedArtist = artist.replace(/-/g, ' ');
      const formattedTitle = title.replace(/-/g, ' ');
      console.log(`Fetching song: ${formattedArtist} - ${formattedTitle}`);
      const url = `/song/${encodeURIComponent(formattedArtist)}/${encodeURIComponent(formattedTitle)}`;
      const response = await api.get(url, { 
          params: { viewType },
          headers: { 
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'If-None-Match': ''
          },
          timeout: 120000
      });
      console.log('API response:', response.data);
      return response.data;
  } catch (error) {
      console.error('Error in getSongByArtistAndTitle:', error);
      if (error.code === 'ECONNABORTED') {
          console.error('Request timed out after 120 seconds');
          throw new Error('Request timed out. The server is taking too long to respond. Please try again later.');
      }
      if (error.response) {
          console.error('Server responded with error:', error.response.status, error.response.data);
          throw new Error(`Server error: ${error.response.status} - ${error.response.data.message || 'Unknown error'}`);
      }
      throw error;
  }
};

export const getArtistByName = async (artistName) => {
  try {
    if (!artistName) {
      console.error('Artist name is undefined or empty');
      return { 
        artist: null,
        remainingSearches: null
      };
    }
    const response = await api.get(`/artists/${encodeURIComponent(artistName)}`);
    const artist = response.data.artist;
    if (artist) {
      // Ensure all required fields are present
      artist.similarArtists = artist.similarArtists || [];
      artist.tags = artist.tags || [];
      artist.bio = artist.bio || { 
        english: { summary: 'No biography available', content: 'No biography available' },
        spanish: { summary: 'No biography available', content: 'No biography available' }
      };
      artist.topTracks = artist.topTracks || [];
      artist.topAlbums = artist.topAlbums || [];
      artist.genre = artist.genre || 'Unknown';
    }

    return { 
      artist,
      remainingSearches: response.data.remainingSearches
    };
  } catch (error) {
    console.error('Error fetching artist by name:', error.message);
    throw error;
  }
};

export const getArtistSongs = async (artistName) => {
  try {
    if (!artistName) throw new Error('Artist name is required');
    const response = await api.get(`/artists/${artistName}/songs`);
    return {
      ...response.data,
      remainingSearches: response.data.remainingSearches
    };
  } catch (error) {
    console.error('Error fetching artist songs:', error.response?.data || error.message);
    throw error;
  }
};

export const getRecentSearches = async () => {
  try {
    const response = await api.get('/recent');
    return response.data;
  } catch (error) {
    console.error('Error fetching recent searches:', error.response?.data || error.message);
    throw error;
  }
};

export const getSearchSuggestions = async (query) => {
  try {
    const response = await api.get('/suggestions', { params: { q: query } });
    
    const scoredSuggestions = response.data.suggestions.map(suggestion => {
      const score = suggestion.toLowerCase().includes(query.toLowerCase()) ? 1 : 0;
      return { suggestion, score };
    });

    const filteredSuggestions = scoredSuggestions
      .filter(({ suggestion, score }) => {
        return score > 0 && 
               !suggestion.match(/\b(vevo|official|music|records|band)\b/gi);
      })
      .sort((a, b) => b.score - a.score)
      .map(({ suggestion }) => suggestion)
      .slice(0, 10);

    return {
      suggestions: filteredSuggestions,
      remainingSearches: response.data.remainingSearches
    };
  } catch (error) {
    console.error('Error fetching search suggestions:', error);
    return { suggestions: [], remainingSearches: null };
  }
};

export const getAlbumSongs = async (artist, album) => {
  try {
    const response = await api.get(`/album/${encodeURIComponent(artist)}/${encodeURIComponent(album)}`);
    let wiki;
    if (response.data.albumInfo.wiki) {
      wiki = {
        summary: response.data.albumInfo.wiki.summary,
        content: response.data.albumInfo.wiki.content
      };
    }
    
    const tracks = Array.isArray(response.data.albumInfo.tracks) 
      ? response.data.albumInfo.tracks.map(track => ({
          ...track,
          videoThumbnails: track.videoThumbnails || null
        }))
      : [];

    return {
      image: response.data.albumInfo.image,
      wikiSummary: wiki || response.data.wiki,
      tracks: tracks,
      albumName: response.data.albumInfo.name,
      artistName: response.data.albumInfo.artistName,
      releaseDate: response.data.albumInfo.releaseDate,
      HowManylisteners: response.data.albumInfo.HowManylisteners,
      playcount: response.data.albumInfo.playcount,
      remainingSearches: response.data.remainingSearches
    };
  } catch (error) {
    console.error('Error fetching album songs:', error);
    throw error;
  }
};

export const subscribeUser = async (email) => {
  try {
    const response = await api.post('/subscribe', { email });
    const newSearchLimit = response.data.freeSearches || response.data.remainingSearches;
    if (newSearchLimit !== undefined) {
      localStorage.setItem('remainingSearches', newSearchLimit.toString());
      localStorage.setItem('subscribedEmail', email);
    }
    return {
      ...response.data,
      remainingSearches: newSearchLimit
    };
  } catch (error) {
    console.error('Subscription error:', error);
    throw error;
  }
};

export const saveSearch = async (query) => {
  try {
    const response = await api.post('/save-search', { query });
    return {
      success: true,
      remainingSearches: response.data.remainingSearches
    };
  } catch (error) {
    console.error('Error saving search:', error);
    return { 
      success: false, 
      remainingSearches: error.response?.data?.remainingSearches || null 
    };
  }
};

export const getRemainingSearches = async () => {
  try {
    const response = await api.get('/remaining-searches');
    console.log('getRemainingSearches' , response.data);
    return response.data.remainingSearches;
  } catch (error) {
    console.error('Error fetching remaining searches:', error);
    return null;
  }
};

export const getTopArtists = async () => {
  try {
    const response = await api.get('/top');
    if (response.data && Array.isArray(response.data.topArtists)) {
      return response.data.topArtists;
    } else {
      console.warn('Unexpected response structure from /top endpoint:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching top artists:', error);
    return [];
  }
};

export const findSimilarTracks = async (song) => {
  try {
      console.log('findSimilarTracksSong:', song);
      if (!song || (!song.title && !song.artist && !song._doc)) {
          console.warn('Invalid song data for finding similar tracks');
          return [];
      }

      const params = {};
      if (song._doc) {
          params.title = song._doc.title;
          params.artist = song._doc.artist;
      } else {
          if (song.title) params.title = song.title;
          if (song.artist) params.artist = song.artist;
      }

      if (!params.title && !params.artist) {
          console.warn('No title or artist found in song data');
          return [];
      }

      console.log('Sending params to /similar-tracks:', params);
      const response = await api.get(`/similar-tracks`, { params });
      return response.data;
  } catch (error) {
      console.error('Error finding similar tracks:', error);
      return [];
  }
};

const checkIfAlternateVersion = (title) => {
  const alternateKeywords = ["remix", "live", "acoustic", "cover", "remaster", "edit", "version"];
  return alternateKeywords.some(keyword => title.toLowerCase().includes(keyword));
};