import React from 'react';
import styles from './Footer.module.css';

const Footer = () => (
  <footer className={styles.mainFooter}>
    <figure className="text-center">
    <a href="/">
    <img src="/singit-logo.webp" alt="Singit logo" className={styles.logo} />
    </a>
    </figure>
    <div className={styles.menuSm}>
      <nav className="flex flex-center">
        <a href="https://singit.io" className="router-link-active">Home</a>
        <a href="https://app.singit.io" className="router-link-active">Signup</a>
        <a href="https://singit.io/en/contact" className="router-link-active">Contact</a>
        <a href="https://singit.io/en/blog" className="router-link-active">Blog</a>
        <a href="https://app.singit.io/login" className="router-link-active">Login</a>
        <a href="https://singit.io/en/app" className="router-link-active">About</a>
      </nav>
      <nav className={styles.socials}>
        <a className={styles.imgLink} href="/" aria-label="Go to our LinkedIn page">
        <img src="/linkedin.svg" alt="Linkedin-logo"/>
        </a>
        <a className={styles.imgLink} href="/" aria-label="Go to our Twitter page">
          <img src="/twitter.svg" alt="Twitter-logo"/>
        </a>
        <a className={styles.imgLink} href="/" aria-label="Go to our Instagram page">
          <img src="/insta.svg" alt="Instagram-logo"/>
        </a>
        <a className={styles.imgLink} href="/" aria-label="Go to our Facebook page">
          <img src="/fb.svg" alt="Facebook-logo"/>
        </a>
      </nav>
    </div>
    <div className={styles.general}>
      <nav className={styles.options}>
        <a href="https://singit.io/en/privacy" aria-label="Read about our privacy policy">Privacy Policy</a>
        <a href="https://singit.io/en/terms" aria-label="Read about our terms of service">Terms of Service</a>
        <a href="/" aria-label="Read about our referrals terms &amp; privacy">Referrals Terms &amp; Privacy</a>
        <a href="/" aria-label="Read how we deal with data privacy">Data Privacy</a>
      </nav>
      <nav className={styles.options}>
        <span>Copyright © 202e Singit</span>
        <span className={styles.separator}>|</span>
        <div>
          <a href="mailto:support@singit.io" aria-label="Send us an email">support@singit.io</a>
          <span className={styles.separator}>|</span>
          <a href="tel:0732241842" aria-label="Call us">073-224-1842</a>
        </div>
      </nav>
    </div>
  </footer>
);

export default Footer;
