// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecentSearchesView_recentSearchesViewH1__-tsEP{
    text-align: center;
}

.RecentSearchesView_loading__DVnmb{
    text-align: center;
    font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/RecentSearchesView/RecentSearchesView.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".recentSearchesViewH1{\n    text-align: center;\n}\n\n.loading{\n    text-align: center;\n    font-size: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recentSearchesViewH1": `RecentSearchesView_recentSearchesViewH1__-tsEP`,
	"loading": `RecentSearchesView_loading__DVnmb`
};
export default ___CSS_LOADER_EXPORT___;
