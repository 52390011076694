import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getArtistByName, getAlbumSongs } from '../../services/api';
import styles from './ArtistView.module.css';
import { FaUsers, FaPlay, FaTags, FaCompactDisc, FaMusic, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { formatSlug } from '../../utils/stringUtils';
import defaultImage from '../../images/default-artist.webp';
import { AuthContext } from '../../App';
import { toast } from 'react-toastify';

const TopTracks = ({ topTracks, artistName, handleSongClick }) => (
    <div className={styles.card}>
        <h2>Top Tracks</h2>
        {topTracks && topTracks.length > 0 ? (
            <ul className={styles.topTracksList}>
                {topTracks.map((track, index) => {
                    const thumbnailUrl = track.videoThumbnails?.default?.url || 
                                         track.videoThumbnails?.medium?.url || 
                                         track.videoThumbnails?.high?.url ||
                                         track.thumbnailUrl || 
                                         defaultImage;

                    return (
                        <li key={track._id || index} onClick={() => handleSongClick(track.name)} className={styles.topTrackItem}>
                            <div className={styles.thumbnailContainer}>
                                <img
                                    src={thumbnailUrl}
                                    alt={`${artistName} - ${track.name}`}
                                    className={styles.thumbnailImage}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = defaultImage;
                                    }}
                                />
                                <div className={styles.playIcon}>
                                    <FaPlay />
                                </div>
                            </div>
                            <div className={styles.songInfo}>
                                <h3 className={styles.songTitle}>{track.name}</h3>
                                <p className={styles.artistName}>{artistName}</p>
                                <p className={styles.viewCount}>
                                    {(track.HowManylisteners || track.playcount || 0).toLocaleString()} views
                                </p>
                            </div>
                        </li>
                    );
                })}
            </ul>
        ) : (
            <p>No top tracks available</p>
        )}
    </div>
);

const TopAlbums = ({ artist, handleAlbumClick }) => (
    <div className={styles.card}>
        <h2>Top Albums</h2>
        {artist.topAlbums && artist.topAlbums.length > 0 ? (
            <ul className={styles.topAlbumsList}>
                {artist.topAlbums.map((album, index) => {
                    const albumCoverUrl = album.image || artist.image || defaultImage;

                    return (
                        <li key={index} onClick={() => handleAlbumClick(album)} className={styles.topAlbumItem}>
                            <div className={styles.thumbnailContainer}>
                                <img
                                    src={albumCoverUrl}
                                    alt={`${artist.artistName} - ${album.name}`}
                                    className={styles.thumbnailImage}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = defaultImage;
                                    }}
                                />
                                <div className={styles.playIcon}>
                                    <FaPlay />
                                </div>
                            </div>
                            <div className={styles.albumInfo}>
                                <h3 className={styles.albumTitle}>{album.name}</h3>
                                <p className={styles.artistName}>{artist.artistName}</p>
                                <p className={styles.albumPlaycount}>
                                    {(album.playcount || 0).toLocaleString()} plays
                                </p>
                            </div>
                        </li>
                    );
                })}
            </ul>
        ) : (
            <p>No top albums available</p>
        )}
    </div>
);

const SimilarArtists = ({ artist, handleArtistSearch }) => {
    console.log('SimilarArtists props:', artist);
    const similarArtists = artist.similarArtists || [];
    
    return (
        <div className={styles.card}>
            <h2>Similar Artists</h2>
            {similarArtists && similarArtists.length > 0 ? (
                <ul className={styles.similarArtistsList}>
                    {similarArtists.map((similarArtist, index) => {
                        const artistName = typeof similarArtist === 'string' ? similarArtist : similarArtist.name || 'Unknown Artist';
                        return (
                            <li key={index} onClick={() => handleArtistSearch(artistName)} className={styles.similarArtistItem}>
                                <div className={styles.thumbnailContainer}>
                                    <img
                                        src={defaultImage}
                                        alt={artistName}
                                        className={styles.thumbnailImage}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = defaultImage;
                                        }}
                                    />
                                </div>
                                <div className={styles.artistInfo}>
                                    <h3 className={styles.artistName}>{artistName}</h3>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p>No similar artists found</p>
            )}
        </div>
    );
};

function ArtistView() {
    const { artistName } = useParams();
    const [artist, setArtist] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [bioLanguage, setBioLanguage] = useState('english');
    const [showFullBio, setShowFullBio] = useState(false);
    const navigate = useNavigate();
    const normalizeSlug = (slug) => slug.replace(/\s+/g, '-').toLowerCase();
    const { isAuthenticated, setShowLoginModal, remainingSearches, setRemainingSearches } = useContext(AuthContext);

    const fetchArtistData = useCallback(async () => {
        if (!artistName) {
          console.error('No artist name provided');
          setError('No artist name provided');
          setLoading(false);
          return;
        }
    
        try {
          setLoading(true);
          const data = await getArtistByName(artistName);
          console.log('Artist data received:', data);
          if (data && data.artist) {
            setArtist(data.artist);
            if (data.remainingSearches !== undefined) {
              setRemainingSearches(data.remainingSearches);
            }
          } else {
            setError('No artist data found');
          }
        } catch (error) {
          console.error('Error fetching artist data:', error);
          if (error.response && error.response.status === 429) {
            handleRateLimitError();
          } else {
            setError(`Failed to load artist data: ${error.message}`);
          }
        } finally {
          setLoading(false);
        }
      }, [artistName, setRemainingSearches]);
    
      useEffect(() => {
        fetchArtistData();
      }, [fetchArtistData]);

    const handleSongClick = (songName) => {
        const formattedArtistName = formatSlug(artist.artistName);
        const formattedSongName = formatSlug(songName);
        navigate(`/song/${formattedArtistName}/${formattedSongName}`);
    };

    const handleTagClick = (tag) => {
        navigate(`/tag/${encodeURIComponent(tag)}`);
    };

    const handleArtistSearch = (artistName) => {
        const formattedName = encodeURIComponent(artistName.toLowerCase().replace(/\s+/g, '-'));
        navigate(`/artist/${formattedName}`);
    };

    const handleRateLimitError = useCallback(() => {
        setRemainingSearches(0);
        if (!isAuthenticated) {
            setShowLoginModal(true);
        }
        toast.error('You have reached the search limit. Please log in or upgrade your account.');
    }, [isAuthenticated, setShowLoginModal, setRemainingSearches]);

    const handleAlbumClick = useCallback(async (album) => {
        if (artist && album && album.name) {
            try {
                const albumData = await getAlbumSongs(artist.artistName, album.name);
                if (albumData) {
                    console.log('HandleAlbumClick: ', albumData);
                    navigate(`/album/${formatSlug(artist.artistName)}/${formatSlug(album.name)}`, {
                        state: { data: albumData }
                    });
                } else {
                    console.error('No songs found for album:', album.name);
                }
            } catch (error) {
                console.error('Error fetching album songs:', error);
            }
        } else {
            console.error('Invalid album data or artist not loaded:', album);
        }
    }, [navigate, artist]);

    if (loading) return <div className={styles.loading}>Loading...</div>;
    if (error) return <div className={styles.error}>{error}</div>;
    if (!artist) return <div className={styles.noData}>No artist data available</div>;

    const seoSchema = {
        "@context": "https://schema.org",
        "@type": "MusicGroup",
        "name": artist.artistName,
        "genre": artist.genre,
        "image": artist.image,
        "sameAs": artist.url,
        "member": artist.members?.map(member => ({
            "@type": "Person",
            "name": member
        })) || [],
        "album": artist.topAlbums?.map(album => ({
            "@type": "MusicAlbum",
            "name": album.name
        })) || []
    };

    const getBioContent = (language) => {
        if (artist && artist.bio && artist.bio[language]) {
            if (typeof artist.bio[language] === 'string') {
                return artist.bio[language];
            } else if (typeof artist.bio[language].content === 'string') {
                return artist.bio[language].content;
            }
        }
        return 'No biography available';
    };

    const fullBio = getBioContent(bioLanguage);
    const truncatedBio = fullBio.substring(0, 500);
    const bioHasMore = fullBio.length > 500;

    return (
        <>
            <Helmet>
                <title>{`${artist.artistName} | Artist Page | Singit Translation`}</title>
                <meta name="description" content={`Discover ${artist.artistName}'s songs, lyrics, and translations on Singit. Explore their discography and learn languages through their music.`} />
                <script type="application/ld+json">{JSON.stringify(seoSchema)}</script>
            </Helmet>
            <div className={styles.artistView}>
                <div className={styles.header}>
                    <h1>{artist.artistName || 'N/A'}</h1>
                    <p onClick={() => handleTagClick(`${artist.genre}`)} className={styles.genre}>{artist.genre || 'Genre not specified'}</p>
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.leftColumn}>
                        <div className={styles.statsCard}>
                            <div className={styles.stat}>
                                <FaUsers className={styles.icon} />
                                <div>
                                    <h3>Listeners</h3>
                                    <p>{artist.HowManyisteners?.toLocaleString() || 'N/A'}</p>
                                </div>
                            </div>
                            <div className={styles.stat}>
                                <FaPlay className={styles.icon} />
                                <div>
                                    <h3>Playcount</h3>
                                    <p>{artist.playcount?.toLocaleString() || 'N/A'}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.biography}>
                            <h2>Biography</h2>
                            <select
                                value={bioLanguage}
                                onChange={(e) => setBioLanguage(e.target.value)}
                                className={styles.languageSelect}
                            >
                                <option value="english">English</option>
                                <option value="spanish">Spanish</option>
                            </select>
                            <p>{showFullBio ? fullBio : truncatedBio}</p>
                            {bioHasMore && (
                                <button onClick={() => setShowFullBio(!showFullBio)} className={styles.showMoreBtn}>
                                    {showFullBio ? (
                                        <>Show Less <FaChevronUp /></>
                                    ) : (
                                        <>Show More <FaChevronDown /></>
                                    )}
                                </button>
                            )}
                        </div>
                        <SimilarArtists artist={artist} handleArtistSearch={handleArtistSearch} />
                        <div className={styles.card}>
                            <h2>Tags</h2>
                            {artist.tags && artist.tags.length > 0 ? (
                                <div className={styles.tagCloud}>
                                    {artist.tags.map((tag, index) => (
                                        <span key={index} className={styles.tag} onClick={() => handleTagClick(tag)}>
                                            <FaTags className={styles.tagIcon} />
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                <p>No tags available</p>
                            )}
                        </div>
                    </div>
                    <div className={styles.rightColumn}>
                    <TopTracks topTracks={artist.topTracks} artistName={artist.artistName} handleSongClick={handleSongClick} />
                        <TopAlbums artist={artist} handleAlbumClick={handleAlbumClick} />
                    </div>
                </div>
            </div>
        </>

    );
}

export default ArtistView;