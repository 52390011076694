import React from 'react';
import styles from './PromoBanner.module.css';
import millionsImage from '../../images/millions.webp';


const MillionSongs = () => {
  return (
    <section className={styles.millionSongs}>
      <div>
        <div>
          <h2>
            Millions of songs for learning at the highest <span>level!</span>
          </h2>
          <p className={`${styles.white} ${styles.f20} ${styles.f400}`}>
            Access to millions of songs, contests, content and more. <br />
            New songs are added every month so there is always something new to learn with.
          </p>
          <div className={`${styles.actions} ${styles.flex} ${styles.alignCenter}`}>
            <button>Start now!</button>
            <a href="/" className={`${styles.white} ${styles.underline}`} aria-label="Subscribe for a free trial">
              3 Days free Trial
            </a>
          </div>
        </div>
        <figure>
          <img src={millionsImage} alt="Millions of songs on SongIt" />
        </figure>
      </div>
    </section>
  );
};

export default MillionSongs;