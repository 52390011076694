import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { searchByTag } from '../../services/api';
import styles from './TagView.module.css';
import defaultImage from '../../images/default-artist.webp';

function TagView() {
    const [artists, setArtists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { tag } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArtistsByTag = async () => {
            try {
                setLoading(true);
                const { results } = await searchByTag(tag);
                setArtists(results);
            } catch (err) {
                console.error('Error fetching artists by tag:', err);
                setError(err.response?.data?.message || 'Failed to load artists. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchArtistsByTag();
    }, [tag]);

    const handleArtistClick = (artistName) => {
        navigate(`/artist/${encodeURIComponent(artistName)}`);
    };

    if (loading) return <div className={styles.loading}>Loading...</div>;
    if (error) return <div className={styles.error}>{error}</div>;

    return (
        <div className={styles.tagView}>
            <h2>Artists tagged with "{tag}"</h2>
            {artists.length === 0 ? (
                <p>No artists found for this tag</p>
            ) : (
                <div className={styles.artistsGrid}>
                    {artists.map((artist, index) => (
                        <div key={index} className={styles.artistCard} onClick={() => handleArtistClick(artist.artistName)}>
                            <img src={artist.imageUrl || defaultImage } alt={artist.artistName} />
                            <h3>{artist.artistName}</h3>
                            <p>{artist.HowManylisteners?.toLocaleString()} listeners</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default TagView;