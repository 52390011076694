// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormattedLyrics_formattedLyrics__dIsn- {
    font-size: 3rem;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .FormattedLyrics_lyrics__gVQzJ {
    display: flex;
    flex-direction: column;
  }
  
  .FormattedLyrics_lyricsLine__QWFUY {
    color: #333;
    margin-bottom: 4px;
    font-weight: 500;
  }
  
  .FormattedLyrics_translatedLine__AISCy {
    color: #666;
    margin-bottom: 16px;
    font-style: italic;
  }
  
  .FormattedLyrics_desktopLyrics__aDaQh {
    display: none;
  }
  
  .FormattedLyrics_mobileLyrics__NeE6J {
    display: block;
  }
  
  @media (min-width: 768px) {
    .FormattedLyrics_desktopLyrics__aDaQh {
      display: block;
    }

    .FormattedLyrics_formattedLyrics__dIsn- {
        font-size: 2rem;
      }

    .FormattedLyrics_mobileLyrics__NeE6J {
      display: none;
    }
  }

  .FormattedLyrics_formattedLyrics__dIsn- {
    font-size: 16px;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .FormattedLyrics_lyricsLine__QWFUY {
    color: #333;
    margin-bottom: 4px;
    font-weight: 500;
  }
  
  .FormattedLyrics_translatedLine__AISCy {
    color: #666;
    margin-bottom: 16px;
    font-style: italic;
  }
  
  @media (max-width: 767px) {
    .FormattedLyrics_formattedLyrics__dIsn- {
      font-size: 14px;
    }
    
    .FormattedLyrics_lyricsLine__QWFUY, .FormattedLyrics_translatedLine__AISCy {
      white-space: normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/FormattedLyrics/FormattedLyrics.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE;MACE,cAAc;IAChB;;IAEA;QACI,eAAe;MACjB;;IAEF;MACE,aAAa;IACf;EACF;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE;MACE,eAAe;IACjB;;IAEA;MACE,mBAAmB;MACnB,yBAAyB;MACzB,qBAAqB;IACvB;EACF","sourcesContent":[".formattedLyrics {\n    font-size: 3rem;\n    line-height: 1.5;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .lyrics {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .lyricsLine {\n    color: #333;\n    margin-bottom: 4px;\n    font-weight: 500;\n  }\n  \n  .translatedLine {\n    color: #666;\n    margin-bottom: 16px;\n    font-style: italic;\n  }\n  \n  .desktopLyrics {\n    display: none;\n  }\n  \n  .mobileLyrics {\n    display: block;\n  }\n  \n  @media (min-width: 768px) {\n    .desktopLyrics {\n      display: block;\n    }\n\n    .formattedLyrics {\n        font-size: 2rem;\n      }\n\n    .mobileLyrics {\n      display: none;\n    }\n  }\n\n  .formattedLyrics {\n    font-size: 16px;\n    line-height: 1.5;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .lyricsLine {\n    color: #333;\n    margin-bottom: 4px;\n    font-weight: 500;\n  }\n  \n  .translatedLine {\n    color: #666;\n    margin-bottom: 16px;\n    font-style: italic;\n  }\n  \n  @media (max-width: 767px) {\n    .formattedLyrics {\n      font-size: 14px;\n    }\n    \n    .lyricsLine, .translatedLine {\n      white-space: normal;\n      overflow-wrap: break-word;\n      word-wrap: break-word;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formattedLyrics": `FormattedLyrics_formattedLyrics__dIsn-`,
	"lyrics": `FormattedLyrics_lyrics__gVQzJ`,
	"lyricsLine": `FormattedLyrics_lyricsLine__QWFUY`,
	"translatedLine": `FormattedLyrics_translatedLine__AISCy`,
	"desktopLyrics": `FormattedLyrics_desktopLyrics__aDaQh`,
	"mobileLyrics": `FormattedLyrics_mobileLyrics__NeE6J`
};
export default ___CSS_LOADER_EXPORT___;
