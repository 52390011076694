import React from 'react';
import styles from './LoadingIndicator.module.css';


const MusicNote1 = ({ className }) => (
  <svg className={className} width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 2C13.75 1.58579 13.4142 1.25 13 1.25C12.5858 1.25 12.25 1.58579 12.25 2V14.5359C11.4003 13.7384 10.2572 13.25 9 13.25C6.37665 13.25 4.25 15.3766 4.25 18C4.25 20.6234 6.37665 22.75 9 22.75C11.6234 22.75 13.75 20.6234 13.75 18V6.243C14.9875 7.77225 16.8795 8.75 19 8.75C19.4142 8.75 19.75 8.41421 19.75 8C19.75 7.58579 19.4142 7.25 19 7.25C16.1005 7.25 13.75 4.8995 13.75 2Z" fill="#1C274C" />
  </svg>
);

const MusicNote2 = ({ className }) => (
  <svg className={className} width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8497 4.70046C19.4524 3.57572 18.353 2.87888 17.1944 3.01749C16.9333 3.04872 16.6802 3.14246 16.4231 3.25741C16.1681 3.37141 15.8568 3.531 15.4744 3.7271L12.999 4.9964C12.7146 5.14208 12.4921 5.25604 12.3014 5.40553C11.8107 5.79032 11.4753 6.34693 11.3591 6.96915C11.314 7.21087 11.3141 7.46594 11.3143 7.79201L11.3143 7.8696C11.3143 7.97429 11.3143 8.07432 11.3145 8.16983L11.3143 14.2981C10.5374 13.5504 9.49228 13.0924 8.34284 13.0924C5.94435 13.0924 4 15.0865 4 17.5462C4 20.006 5.94435 22 8.34284 22C10.7413 22 12.6857 20.006 12.6857 17.5462V11.1832C13.1164 11.4088 13.6124 11.5083 14.1199 11.4476C14.381 11.4164 14.6341 11.3227 14.8912 11.2077C15.1462 11.0937 15.4574 10.9341 15.8399 10.738L18.3152 9.46872C18.5997 9.32303 18.8222 9.20907 19.0128 9.05959C19.5036 8.67479 19.839 8.11819 19.9551 7.49596C20.0002 7.25425 20.0001 6.99919 20 6.67313L20 6.5955C20 6.15702 20 5.80015 19.9854 5.51527C19.9706 5.228 19.9392 4.95394 19.8497 4.70046Z" fill="#1C274C" />
  </svg>
);

const MusicNote3 = ({ className }) => (
  <svg className={className} width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0909 11.9629L19.3636 8.63087V14.1707C18.8126 13.8538 18.1574 13.67 17.4545 13.67C15.4964 13.67 13.9091 15.096 13.9091 16.855C13.9091 18.614 15.4964 20.04 17.4545 20.04C19.4126 20.04 21 18.614 21 16.855C21 16.855 21 16.8551 21 16.855L21 7.49236C21 6.37238 21 5.4331 20.9123 4.68472C20.8999 4.57895 20.8852 4.4738 20.869 4.37569C20.7845 3.86441 20.6352 3.38745 20.347 2.98917C20.2028 2.79002 20.024 2.61055 19.8012 2.45628C19.7594 2.42736 19.716 2.39932 19.6711 2.3722L19.6621 2.36679C18.8906 1.90553 18.0233 1.93852 17.1298 2.14305C16.2657 2.34086 15.1944 2.74368 13.8808 3.23763L11.5963 4.09656C10.9806 4.32806 10.4589 4.52419 10.0494 4.72734C9.61376 4.94348 9.23849 5.1984 8.95707 5.57828C8.67564 5.95817 8.55876 6.36756 8.50501 6.81203C8.4545 7.22978 8.45452 7.7378 8.45455 8.33743V16.1307C7.90347 15.8138 7.24835 15.63 6.54545 15.63C4.58735 15.63 3 17.056 3 18.815C3 20.574 4.58735 22 6.54545 22C8.50355 22 10.0909 20.574 10.0909 18.815C10.0909 18.815 10.0909 18.8151 10.0909 18.815L10.0909 11.9629Z" fill="#1C274C" />
  </svg>
);

const LoadingIndicator = () => {
  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.musicSheet}>
        <div className={styles.loadingContainer}>
          <MusicNote1 className={styles.musicNote} />
          <MusicNote2 className={styles.musicNote} />
          <MusicNote3 className={styles.musicNote} />
          <MusicNote1 className={styles.musicNote} />
          <MusicNote2 className={styles.musicNote} />
        </div>
      </div>
    </div>
  );
};

export default LoadingIndicator;