import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FaPlay } from 'react-icons/fa';
import styles from './AlbumView.module.css';
import { formatSlug } from '../../utils/stringUtils';
import { getAlbumSongs } from '../../services/api';
import defaultImage from '../../images/default-artist.webp';

function AlbumView() {
    const { artist, album } = useParams();
    const navigate = useNavigate();
    const [albumData, setAlbumData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showFullBio, setShowFullBio] = useState(false);

    useEffect(() => {
        const fetchAlbumSongs = async () => {
            try {
                const fixedArtist = artist.replace(/-/g, ' ');
                const fixedAlbum = album.replace(/-/g, ' ');
                const data = await getAlbumSongs(fixedArtist, fixedAlbum);
                setAlbumData(data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching album data:', err);
                setError('Failed to load album information. Please try again.');
                setLoading(false);
            }
        };

        fetchAlbumSongs();
    }, [artist, album]);

    const handleSongClick = (song) => {
        navigate(`/song/${formatSlug(albumData.artistName)}/${formatSlug(song.name)}`);
    };

    if (loading) return <div className={styles.loading}>Loading...</div>;
    if (error) return <div className={styles.error}>{error}</div>;
    if (!albumData) return <div className={styles.noData}>No album data available</div>;

    const fullBio = albumData.wikiSummary?.content?.replace(/(<([^>]+)>)/gi, '') || 'Bio not available';
    const truncatedBio = fullBio.length > 500 ? fullBio.slice(0, 500) : fullBio;

    const seoSchema = {
        "@context": "https://schema.org",
        "@type": "MusicAlbum",
        "name": albumData.albumName,
        "byArtist": {
            "@type": "MusicGroup",
            "name": albumData.artistName
        },
        "numTracks": albumData.tracks?.length || 0,
        "track": albumData.tracks?.map((track, index) => ({
            "@type": "MusicRecording",
            "name": track.name,
            "position": index + 1,
            "duration": track.duration ? `PT${Math.floor(track.duration / 60)}M${track.duration % 60}S` : undefined
        })) || [],
        "image": albumData.image,
        "datePublished": albumData.releaseDate
    };

    return (
        <>
            <Helmet>
                <title>{`${albumData.albumName} by ${albumData.artistName} | Album | Singit Translation`}</title>
                <meta name="description" content={`Explore the album "${albumData.albumName}" by ${albumData.artistName}. Translate and understand the lyrics of all songs in this album with Singit Translation.`} />
                <script type="application/ld+json">{JSON.stringify(seoSchema)}</script>
            </Helmet>
            <div className={styles.albumView}>
                <div className={styles.header}>
                    <img className={styles.albumImage} src={albumData.image || defaultImage} alt={`${albumData.albumName} cover`} />
                    <div className={styles.albumInfo}>
                        <h1>{albumData.artistName} - {albumData.albumName}</h1>
                        {albumData.wikiSummary && albumData.wikiSummary.content && (
                            <div className={styles.albumDescription}>
                                <h2>About the Album</h2>
                                <p>{showFullBio ? fullBio : truncatedBio}
                                    {!showFullBio && fullBio.length > 500 && '...'}
                                </p>
                                {fullBio.length > 500 && (
                                    <button onClick={() => setShowFullBio(!showFullBio)} className={styles.viewMoreBtn}>
                                        {showFullBio ? 'View Less' : 'View More'}
                                    </button>
                                )}
                            </div>
                        )}
                        <p className={styles.releaseDate}>Release Date: {albumData.releaseDate || 'N/A'}</p>
                        <p className={styles.stats}>
                            <span>{albumData.playcount || 'N/A'} plays</span>
                            <span>{albumData.HowManylisteners || 'N/A'} listeners</span>
                        </p>
                        <div className={styles.tags}>
                            {albumData.tags && albumData.tags.map((tag, index) => (
                                <span key={index} className={styles.tag}>{tag}</span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.mainContent}>
                        <div className={styles.tracksCard}>
                            <h2>{albumData.albumName} By {albumData.artistName} Tracks</h2>
                            {albumData.tracks && albumData.tracks.length > 0 ? (
                                <ul className={styles.tracksList}>
                                    {albumData.tracks.map((song, index) => {
                                        const thumbnailUrl =
                                            (song.videoThumbnails && song.videoThumbnails.default && song.videoThumbnails.default.url) ||
                                            albumData.image ||
                                            defaultImage;

                                        return (
                                            <li key={index} onClick={() => handleSongClick(song)} className={styles.trackItem}>
                                                <div
                                                    className={styles.trackBackground}
                                                    style={{ backgroundImage: `url(${thumbnailUrl})` }}
                                                >
                                                    <div className={styles.overlay}>
                                                        <span className={styles.trackNumber}>#{index + 1}</span>
                                                        <span className={styles.trackName}>{song.name}</span>
                                                        <span className={styles.trackDuration}>{formatDuration(song.duration)}</span>
                                                        <span className={styles.trackViewCount}>{song.viewCount || '0'} views</span>
                                                        <div className={styles.playIcon}>
                                                            <FaPlay />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <p>No tracks available for this album.</p>
                            )}
                        </div>
                    </div>

                    <div className={styles.sidebar}>
                        <div className={styles.sidebarSection}>
                            <h3>Album Information</h3>
                            <ul className={styles.ulInfo}>
                                <li><strong>Artist:</strong> {albumData.artistName}</li>
                                <li><strong>Album:</strong> {albumData.albumName}</li>
                                <li><strong>Release Date:</strong> {albumData.releaseDate || 'N/A'}</li>
                                <li><strong>Listeners:</strong> {albumData.HowManylisteners || 'N/A'}</li>
                                <li><strong>Playcount:</strong> {albumData.playcount || 'N/A'}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function formatDuration(duration) {
    if (!duration) return 'N/A';
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

export default AlbumView;