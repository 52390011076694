import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ currentView }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  const decodeAndFormatSegment = (segment) => {
    return decodeURIComponent(segment)
      .replace(/-/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Prevent breadcrumbs from appearing on the home page
  if (location.pathname === '/') {
    return null;
  }

  const renderBreadcrumbs = () => {
    if (currentView === 'searchResults' || location.pathname.startsWith('/search-results')) {
      return (
        <>
          <Link to="/">Home</Link>
          <span> &gt; </span>
          <span>Search Results</span>
        </>
      );
    }

    if (currentView === 'songView' || pathSegments[0] === 'song') {
      if (pathSegments.length >= 3) {
        const artistSlug = pathSegments[1];
        const songSlug = pathSegments[2];
        return (
          <>
            <Link to="/">Home</Link>
            <span> &gt; </span>
            <Link to={`/artist/${artistSlug}`}>{decodeAndFormatSegment(artistSlug)}</Link>
            <span> &gt; </span>
            <span>{decodeAndFormatSegment(songSlug)}</span>
          </>
        );
      }
    }

    if (currentView === 'artistView' || pathSegments[0] === 'artist') {
      if (pathSegments.length >= 2) {
        const artistSlug = pathSegments[1];
        return (
          <>
            <Link to="/">Home</Link>
            <span> &gt; </span>
            <span>{decodeAndFormatSegment(artistSlug)}</span>
          </>
        );
      }
    }

    if (currentView === 'albumView' || pathSegments[0] === 'album') {
      if (pathSegments.length >= 3) {
        const artistSlug = pathSegments[1];
        const albumSlug = pathSegments[2];
        return (
          <>
            <Link to="/">Home</Link>
            <span> &gt; </span>
            <Link to={`/artist/${artistSlug}`}>{decodeAndFormatSegment(artistSlug)}</Link>
            <span> &gt; </span>
            <span>{decodeAndFormatSegment(albumSlug)} (album)</span>
          </>
        );
      }
    }

    // Default case: just show Home > Current Page
    return (
      <>
        <Link to="/">Home</Link>
        <span> &gt; </span>
        <span>{decodeAndFormatSegment(pathSegments[pathSegments.length - 1])}</span>
      </>
    );
  };

  return (
    <nav className={styles.breadcrumbs}>
      {renderBreadcrumbs()}
    </nav>
  );
};

export default Breadcrumbs;