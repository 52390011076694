export const formatSlug = (text) => {
  if (typeof text !== 'string' || !text) {
    console.error('Invalid input to formatSlug:', text);
    return '';
  }
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove all non-word chars
    .replace(/[\s_-]+/g, '-') // Replace spaces and underscores with hyphens
    .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
};