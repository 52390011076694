// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShareButtons_shareButtons__jkcaP h2{
    text-align: left!important;
    font-size: 18px;
}
.ShareButtons_FlexButtons__upZWw{
    display: flex;
}

.ShareButtons_shareButtons__jkcaP {
    gap: 10px;
    align-items: center;
  }
  
  .ShareButtons_FlexButtons__upZWw > button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
  }
  
  button:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  button img {
    width: 24px;
    height: 24px;
  }
  
  
  button.ShareButtons_copy__kIW-O img {
    filter: invert(8%) sepia(0%) saturate(0%) hue-rotate(311deg) brightness(96%) contrast(85%);
  }`, "",{"version":3,"sources":["webpack://./src/components/ShareButtons/ShareButtons.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,eAAe;AACnB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gCAAgC;EAClC;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;;EAGA;IACE,0FAA0F;EAC5F","sourcesContent":[".shareButtons h2{\n    text-align: left!important;\n    font-size: 18px;\n}\n.FlexButtons{\n    display: flex;\n}\n\n.shareButtons {\n    gap: 10px;\n    align-items: center;\n  }\n  \n  .FlexButtons > button {\n    background: none;\n    border: none;\n    cursor: pointer;\n    font-size: 24px;\n    padding: 10px;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    transition: background 0.3s ease;\n  }\n  \n  button:hover {\n    background: rgba(0, 0, 0, 0.1);\n  }\n  \n  button img {\n    width: 24px;\n    height: 24px;\n  }\n  \n  \n  button.copy img {\n    filter: invert(8%) sepia(0%) saturate(0%) hue-rotate(311deg) brightness(96%) contrast(85%);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareButtons": `ShareButtons_shareButtons__jkcaP`,
	"FlexButtons": `ShareButtons_FlexButtons__upZWw`,
	"copy": `ShareButtons_copy__kIW-O`
};
export default ___CSS_LOADER_EXPORT___;
