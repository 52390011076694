// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/TagView/TagView.module.css */
.TagView_tagView__T2mw2 {
    padding: 20px;
}

.TagView_artistsGrid__3fWt0 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.TagView_artistCard__K0OdS {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
}

.TagView_artistCard__K0OdS:hover {
    transform: scale(1.05);
}

.TagView_artistCard__K0OdS img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.TagView_loading__LaUOT, .TagView_error__3xxiL {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/TagView/TagView.module.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,4DAA4D;IAC5D,SAAS;AACb;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["/* src/components/TagView/TagView.module.css */\n.tagView {\n    padding: 20px;\n}\n\n.artistsGrid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n    gap: 20px;\n}\n\n.artistCard {\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 10px;\n    text-align: center;\n    cursor: pointer;\n    transition: transform 0.2s;\n}\n\n.artistCard:hover {\n    transform: scale(1.05);\n}\n\n.artistCard img {\n    width: 100%;\n    height: 200px;\n    object-fit: cover;\n    border-radius: 4px;\n}\n\n.loading, .error {\n    text-align: center;\n    font-size: 18px;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagView": `TagView_tagView__T2mw2`,
	"artistsGrid": `TagView_artistsGrid__3fWt0`,
	"artistCard": `TagView_artistCard__K0OdS`,
	"loading": `TagView_loading__LaUOT`,
	"error": `TagView_error__3xxiL`
};
export default ___CSS_LOADER_EXPORT___;
