import React from 'react';
import styles from './FeatureSection.module.css';

function FeatureSection({ title, description, imageSrc }) {
  return (
    <section className={styles.featureSection}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <img src={imageSrc} alt={title} className={styles.image} />
    </section>
  );
}

export default FeatureSection;