import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SearchResults.module.css';
import { formatSlug } from '../../utils/stringUtils';
import defaultImage from '../../images/default-artist.webp';
import { FaUser, FaMusic, FaSearch } from 'react-icons/fa';

function SearchResults({ searchResult, onSongSelect, onArtistSelect, onSuggestionSelect }) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Received search result:", searchResult);
  }, [searchResult]);

  if (!searchResult || (!searchResult.results && !searchResult.suggestions)) {
    console.log("Search result is undefined or has no results and suggestions");
    return <div className={styles.noResults}>No results found. Please try a different search term.</div>;
  }

  const results = searchResult.results || [];
  const suggestions = searchResult.suggestions || [];

  if (results.length === 0 && suggestions.length === 0) {
    console.log("No results or suggestions found");
    return <div className={styles.noResults}>No results found. Please try a different search term.</div>;
  }

  const handleItemClick = (item, type) => {
    console.log("Item clicked:", item, "Type:", type);
    if (type === 'track') {
      onSongSelect(item);
      navigate(`/song/${formatSlug(item.artist)}/${formatSlug(item.title)}`);
    } else if (type === 'artist') {
      onArtistSelect(item);
      navigate(`/artist/${formatSlug(item.artistName)}`);
    } else if (type === 'suggestion') {
      onSongSelect(item);
      navigate(`/song/${formatSlug(item.artist)}/${formatSlug(item.title)}`);

    }
  };

  const renderArtist = (artist) => (
    <div className={styles.resultCard} onClick={() => handleItemClick(artist, 'artist')}>
      <img 
        src={artist.thumbnailUrl || artist.imageUrl || defaultImage} 
        alt={artist.artistName} 
        className={styles.thumbnail}
      />
      <div className={styles.cardContent}>
        <FaUser className={styles.icon} />
        <h3>{artist.artistName}</h3>
        <p>{artist.HowManylisteners?.toLocaleString()} listeners</p>
        <button className={styles.viewButton}>View Artist</button>
      </div>
    </div>
  );

  const renderTrack = (track) => (
    <div className={styles.resultCard} onClick={() => handleItemClick(track, 'track')}>
      <img 
        src={track.thumbnailUrl || defaultImage} 
        alt={track.title} 
        className={styles.thumbnail}
      />
      <div className={styles.cardContent}>
        <FaMusic className={styles.icon} />
        <h3>{track.title}</h3>
        <p>{track.artist}</p>
        <button className={styles.viewButton}>View Song</button>
      </div>
    </div>
  );

  const renderSuggestion = (suggestion) => (
    <div className={styles.suggestionItem} onClick={() => handleItemClick(suggestion, 'suggestion')}>
      <img 
        src={defaultImage} 
        alt={suggestion} 
        className={styles.thumbnail}
      />
      <div className={styles.cardContent}>
      <FaSearch className={styles.suggestionIcon} />
        <h3>{suggestion}</h3>
        <button className={styles.viewButton}>View Song</button>
      </div>
    </div>
  );

  return (
    <div className={styles.searchResultsContainer}>
      <h1>Search Results</h1>
      {results.length > 0 && (
        <>
          {results.some(item => item.type === 'artist') && (
            <div>
              <h2>Artists</h2>
              <div className={styles.resultsList}>
                {results.filter(item => item.type === 'artist').map((artist, index) => (
                  <React.Fragment key={`artist-${index}`}>
                    {renderArtist(artist)}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
          {results.some(item => item.type === 'track') && (
            <div>
              <h2>Tracks</h2>
              <div className={styles.resultsList}>
                {results.filter(item => item.type === 'track').map((track, index) => (
                  <React.Fragment key={`track-${index}`}>
                    {renderTrack(track)}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {suggestions.length > 0 && (
        <div>
          <h2>Suggestions</h2>
          <div className={styles.suggestionsList}>
            {suggestions.map((suggestion, index) => (
              <React.Fragment key={`suggestion-${index}`}>
                {renderSuggestion(suggestion)}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchResults;