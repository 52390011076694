import React from 'react';
import styles from './ShareButtons.module.css';
import facebookIcon from '../../images/facebook-icon.png';
import twitterIcon from '../../images/twitter-icon.png';
import whatsappIcon from '../../images/whatsapp.png';
import copyIcon from '../../images/copy-icon.png';

const ShareButtons = ({ url }) => {
  return (
    <div className={styles.shareButtons}>
      <div className={styles.FlexButtons}>
        <button onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank')} className={styles.facebook}>
          <img src={facebookIcon} alt="Facebook" />
        </button>
        <button onClick={() => window.open(`https://twitter.com/intent/tweet?url=${url}`, '_blank')} className={styles.twitter}>
          <img src={twitterIcon} alt="Twitter" />
        </button>
        <button onClick={() => window.open(`https://wa.me/?text=${url}`, '_blank')} className={styles.whatsapp}>
          <img src={whatsappIcon} alt="WhatsApp" />
        </button>
        <button onClick={() => navigator.clipboard.writeText(url)} className={styles.copy}>
          <img src={copyIcon} alt="Copy Link" />
        </button>
      </div>
    </div>
  );
};

export default ShareButtons;