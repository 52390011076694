import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getTopArtists } from '../../services/api';
import styles from './TopArtistsView.module.css';
import defaultImage from '../../images/default-artist.webp';

const TopArtistsView = () => {
  const [topArtists, setTopArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTopArtists = async () => {
      try {
        const data = await getTopArtists();
        setTopArtists(data || []);
      } catch (error) {
        console.error('Error fetching top artists:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopArtists();
  }, []);

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.topArtistsView}>
      <h1>Top Artists</h1>
      <ul className={styles.artistList}>
        {topArtists.map((artist, index) => (
          <li key={index} className={styles.artistItem}>
            <Link to={`/artist/${encodeURIComponent(artist.artistName)}`}>
              <div className={styles.thumbnailContainer}>
                <img src={artist.imageUrl || defaultImage} alt={artist.artistName} className={styles.artistImage} />
              </div>
              <div className={styles.artistInfo}>
                <h2 className={styles.artistName}>{artist.artistName}</h2>
                {artist.genre && <p className={styles.artistGenre}>{artist.genre}</p>}
                {artist.popularity && <p className={styles.artistPopularity}>{artist.popularity} followers</p>}
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {topArtists.length === 24 && (
        <button className={styles.loadMoreButton} onClick={() => {/* Load more logic */}}>
          Load More
        </button>
      )}
    </div>
  );
};

export default TopArtistsView;