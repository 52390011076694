import React, { useState, useContext } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { AuthContext } from '../../App';
import styles from './NewsletterSubscription.module.css';

const NewsletterSubscription = ({ artist }) => {
  const [email, setEmail] = useState('');
  const [state, handleSubmit] = useForm("xzzpgakr");
  const { handleSubscriptionSuccess } = useContext(AuthContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await handleSubmit(e);
    if (result.response && result.response.ok) {
      handleSubscriptionSuccess(email);
      setEmail('');
    }
  };

  if (state.succeeded) {
    return <p>Thanks for subscribing!</p>;
  }

  return (
    <div className={styles.newsletterContainer}>
      <h2 className={styles.title}>
        Receive the latest lyrics<br />
        translated from{' '}
        {artist ? (
          <span className={styles.artistName}>{artist}</span>
        ) : (
          'Singit'
        )}
        !
      </h2>
      <p className={styles.subtitle}>Subscribe to our newsletter</p>
      <form onSubmit={onSubmit} className={styles.newsletterForm}>
        <input
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your Email"
          required
          className={styles.emailInput}
        />
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
        <button type="submit" disabled={state.submitting} className={styles.subscribeButton}>
          Subscribe
        </button>
      </form>
    </div>
  );
};

export default NewsletterSubscription;