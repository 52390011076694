import React from 'react';
import styles from './FormattedLyrics.module.css';

const FormattedLyrics = ({ originalLyrics, translatedLyrics }) => {
  // Function to clean and preprocess lyrics
  const cleanLyrics = (text) => {
    if (typeof text !== 'string') return '';
    return text
      .replace(/\[.*?\]/g, '') // Remove bracketed content
      .replace(/\*+/g, '') // Remove asterisks
      .replace(/([a-zA-Z])([A-Z])/g, '$1 $2') // Add space between connected words
      .trim();
  };

  // Function to split lyrics into lines and clean them
  const splitIntoLines = (text) => {
    if (typeof text !== 'string') return [];
    return cleanLyrics(text)
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0);
  };

  // Function to split a line into chunks of words
  const splitLineIntoChunks = (line, wordsPerChunk) => {
    const words = line.split(/\s+/);
    const chunks = [];
    for (let i = 0; i < words.length; i += wordsPerChunk) {
      chunks.push(words.slice(i, i + wordsPerChunk).join(' '));
    }
    return chunks;
  };

  // Handle the case where lyrics might be nested in an object
  const originalLyricsText = typeof originalLyrics === 'object' ? originalLyrics.text : originalLyrics;
  const translatedLyricsText = typeof translatedLyrics === 'object' ? translatedLyrics.text : translatedLyrics;

  const originalLines = splitIntoLines(originalLyricsText || 'Lyrics not available');
  const translatedLines = splitIntoLines(translatedLyricsText || 'Translation not available');

  // Ensure we have an equal number of lines
  const maxLines = Math.max(originalLines.length, translatedLines.length);
  while (originalLines.length < maxLines) originalLines.push('');
  while (translatedLines.length < maxLines) translatedLines.push('');

  const renderLyrics = () => {
    const desktopWordsPerLine = 8;
    const mobileWordsPerLine = 4;

    return (
      <>
        <div className={styles.desktopLyrics}>
          {originalLines.map((line, index) => {
            const originalChunks = splitLineIntoChunks(line, desktopWordsPerLine);
            const translatedChunks = splitLineIntoChunks(translatedLines[index], desktopWordsPerLine);
            const maxChunks = Math.max(originalChunks.length, translatedChunks.length);
            
            return Array.from({ length: maxChunks }).map((_, chunkIndex) => (
              <React.Fragment key={`${index}-${chunkIndex}`}>
                <div className={styles.lyricsLine}>{originalChunks[chunkIndex] || ''}</div>
                <div className={styles.translatedLine}>{translatedChunks[chunkIndex] || ''}</div>
              </React.Fragment>
            ));
          })}
        </div>
        <div className={styles.mobileLyrics}>
          {originalLines.map((line, index) => {
            const originalChunks = splitLineIntoChunks(line, mobileWordsPerLine);
            const translatedChunks = splitLineIntoChunks(translatedLines[index], mobileWordsPerLine);
            const maxChunks = Math.max(originalChunks.length, translatedChunks.length);
            
            return Array.from({ length: maxChunks }).map((_, chunkIndex) => (
              <React.Fragment key={`${index}-${chunkIndex}`}>
                <div className={styles.lyricsLine}>{originalChunks[chunkIndex] || ''}</div>
                <div className={styles.translatedLine}>{translatedChunks[chunkIndex] || ''}</div>
              </React.Fragment>
            ));
          })}
        </div>
      </>
    );
  };

  return <div className={styles.formattedLyrics}>{renderLyrics()}</div>;
};

export default FormattedLyrics;