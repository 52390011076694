import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { searchGeneral, getSearchSuggestions, saveSearch } from '../../services/api';
import { AuthContext } from '../../App';
import styles from './SearchBar.module.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

function SearchBar({ onSearch, onLimitReached }) {
  const { user, remainingSearches, setRemainingSearches: updateRemainingSearches } = useContext(AuthContext);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const searchBarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRateLimitError = useCallback(() => {
    setIsRateLimited(true);
    updateRemainingSearches(0);
    if (onLimitReached) onLimitReached();
  }, [onLimitReached, updateRemainingSearches]);

  const handleSaveSearch = async (searchQuery) => {
    try {
      await saveSearch(searchQuery);
    } catch (error) {
      console.error('Error saving search:', error);
    }
  };

  const filterAndFormatSuggestions = useCallback((suggestions, queryText) => {
    const normalizedQuery = queryText.toLowerCase();
    return suggestions
      .filter(suggestion => {
        const normalizedSuggestion = suggestion.toLowerCase();
        const unwantedTerms = ['#shorts', 'whatsapp', 'order', 'youtube', 'facebook', 'twitter', 'instagram', 'tiktok', 'vevo', 'official'];
        if (unwantedTerms.some(term => normalizedSuggestion.includes(term))) return false;
        if (normalizedSuggestion.includes('?') || normalizedSuggestion.split(' ').length > 10) return false;
        if (suggestion.includes('-')) {
          const [artist, title] = suggestion.split('-').map(part => part.trim());
          return artist && title && (artist.length <= 50 && title.length <= 50);
        }
        return normalizedSuggestion.includes(normalizedQuery) && suggestion.length <= 50;
      })
      .map(suggestion => {
        if (!suggestion.includes('-')) {
          return `${suggestion} - Top Tracks`;
        }
        return suggestion;
      })
      .slice(0, 10);
  }, []);

  const fetchSuggestions = useCallback(async (query) => {
    if (query.length > 2 && !isRateLimited) {
      try {
        const { suggestions, remainingSearches, error } = await getSearchSuggestions(query);
        if (error === 'RATE_LIMIT_REACHED') {
          handleRateLimitError();
          return;
        }
        if (remainingSearches !== undefined) {
          updateRemainingSearches(remainingSearches);
        }
        const filteredSuggestions = filterAndFormatSuggestions(suggestions, query);
        setSuggestions(filteredSuggestions);
        setShowSuggestions(filteredSuggestions.length > 0);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [isRateLimited, handleRateLimitError, filterAndFormatSuggestions, updateRemainingSearches]);

  const debouncedFetchSuggestions = useCallback(
    debounce((query) => {
      if (!isRateLimited) {
        fetchSuggestions(query);
      }
    }, 300),
    [fetchSuggestions, isRateLimited]
  );

  const handleSearch = async (searchQuery) => {
    setIsLoading(true);
    setError(null);
    setShowSuggestions(false);

    try {
        const results = await searchGeneral(searchQuery);
        console.log("Search results:", results);
        if (results.results && results.results.length > 0 || results.suggestions && results.suggestions.length > 0) {
            await handleSaveSearch(searchQuery);
            if (results.remainingSearches !== undefined) {
                updateRemainingSearches(results.remainingSearches);
            }
            onSearch(results);
            navigate('/search-results');
        } else {
            setError('No results found. Please try a different search term.');
            onSearch(null);
        }
    } catch (error) {
        console.error('Search error:', error);
        if (error.response && error.response.status === 500) {
            setError('An error occurred on the server. Please try again later.');
        } else if (error.message === 'RATE_LIMIT_REACHED') {
            handleRateLimitError();
        } else {
            setError('An error occurred while searching. Please try again.');
        }
        onSearch(null);
    } finally {
        setIsLoading(false);
    }
};
  
  useEffect(() => {
    if (remainingSearches > 0 && isRateLimited) {
      setIsRateLimited(false);
    }
  }, [remainingSearches, isRateLimited]);

  useEffect(() => {
    if (!isRateLimited) {
      debouncedFetchSuggestions(query);
    }
  }, [query, debouncedFetchSuggestions, isRateLimited]);

  useEffect(() => {
    setShowSuggestions(false);
    setSuggestions([]);
    setQuery('');
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!query.trim() || isRateLimited) return;
    handleSearch(query);
  };

  const handleClear = () => {
    setQuery('');
    setError(null);
    onSearch(null);
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = async (suggestion) => {
    setQuery(suggestion);
    handleSearch(suggestion);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedSuggestionIndex(prev =>
        prev < suggestions.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedSuggestionIndex(prev => prev > 0 ? prev - 1 : -1);
    } else if (e.key === 'Enter' && selectedSuggestionIndex !== -1) {
      e.preventDefault();
      handleSuggestionClick(suggestions[selectedSuggestionIndex]);
    }
  };

  return (
    <>
      <div ref={searchBarRef} className={styles.searchBarContainer}>
        <form onSubmit={handleSubmit} className={styles.searchBar}>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search"
              className={styles.input}
              disabled={isLoading}
            />
            {query && (
              <button type="button" onClick={handleClear} className={styles.clearButton}>
                <FaTimes />
              </button>
            )}
            <button type="submit" disabled={isLoading} className={styles.submitButton}>
              <FaSearch />
            </button>
          </div>
        </form>
        {error && <div className={styles.errorMessage}>{error}</div>}
        {showSuggestions && suggestions.length > 0 && (
          <ul className={styles.suggestionsList}>
            {suggestions.map((suggestion, index) => {
              const [artist, title] = suggestion.split('-').map(part => part.trim());
              return (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className={`${styles.suggestionItem} ${index === selectedSuggestionIndex ? styles.selected : ''}`}
                >
                  <FaSearch className={styles.suggestionIcon} />
                  <span className={styles.suggestionsListSpan}>
                    <strong>{artist}</strong> - {title}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {isLoading && <LoadingIndicator />}
    </>
  );
}

export default SearchBar;