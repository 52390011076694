// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeatureSection_featureSection__niGWo {
    flex: 1 1;
    text-align: center;
    padding: 20px;
  }
  
  .FeatureSection_title__7OTTO {
    color: #5e35b1;
    font-size: 28px;
    margin-bottom: 15px;
  }
  
  .FeatureSection_description__xsK7r {
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .FeatureSection_imageWrapper__GaklS {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .FeatureSection_imageWrapper__GaklS:hover {
    transform: translateY(-5px);
  }
  
  .FeatureSection_image__oSoFW {
    max-width: 100%;
    border-radius: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/components/FeatureSection/FeatureSection.module.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,cAAc;IACd,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,+BAA+B;EACjC;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB","sourcesContent":[".featureSection {\n    flex: 1;\n    text-align: center;\n    padding: 20px;\n  }\n  \n  .title {\n    color: #5e35b1;\n    font-size: 28px;\n    margin-bottom: 15px;\n  }\n  \n  .description {\n    color: #666;\n    margin-bottom: 30px;\n    line-height: 1.6;\n  }\n  \n  .imageWrapper {\n    background-color: #fff;\n    border-radius: 10px;\n    padding: 20px;\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s ease;\n  }\n  \n  .imageWrapper:hover {\n    transform: translateY(-5px);\n  }\n  \n  .image {\n    max-width: 100%;\n    border-radius: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featureSection": `FeatureSection_featureSection__niGWo`,
	"title": `FeatureSection_title__7OTTO`,
	"description": `FeatureSection_description__xsK7r`,
	"imageWrapper": `FeatureSection_imageWrapper__GaklS`,
	"image": `FeatureSection_image__oSoFW`
};
export default ___CSS_LOADER_EXPORT___;
