import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MultipleSongsView.module.css';
import defaultImage from '../../images/default-artist.webp';

function MultipleSongsView({ results }) {
  const songResults = Array.isArray(results) ? results : [results];
  console.log('songResults', songResults);

  return (
    <div className={styles.multipleSongsView}>
      <div className={styles.resultGrid}>
        {songResults.map((result, index) => {
          console.log('Processing result:', result);

          const songData = result.result || result;
          
          if (!songData || songData.artist === 'Unknown Artist') {
            console.log('Skipping invalid songData');
            return null;
          }

          const thumbnailUrl = songData.videoThumbnails?.default?.url || 
                               songData.thumbnailUrl || 
                               defaultImage;

          const title = songData.title || result.query || 'Unknown Title';
          const artist = songData.artist;
          const views = songData.playcount || songData.HowManylisteners || 0;

          console.log('Rendered item:', { title, artist, views, thumbnailUrl });

          return (
            <Link
              key={index}
              to={`/song/${encodeURIComponent(artist)}/${encodeURIComponent(title)}`}
              className={styles.resultItem}
            >
              <div className={styles.thumbnailContainer}>
                <img
                  src={thumbnailUrl}
                  alt={`${artist} - ${title}`}
                  className={styles.thumbnailImage}
                  onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
                />
                <div className={styles.duration}>{songData.duration || '0:00'}</div>
              </div>
              <div className={styles.songInfo}>
                <h3 className={styles.songTitle}>{title}</h3>
                <p className={styles.artistName}>{artist}</p>
                <p className={styles.viewCount}>{views} views</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default MultipleSongsView;